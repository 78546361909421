(function ($, Drupal, drupalSettings, cookies) {
  /***** gestion du cookie darkmode *****/
  const NIGHT_START_HOURS = 17;
  const NIGHT_START_MINUTES = 30;
  const NIGHT_END_HOURS = 6;
  const NIGHT_END_MINUTES = 0;

  if (typeof cookies.get('idexdarkmode') === 'undefined')
    cookies.set('idexdarkmode', 0, { path: '/' });

  let currentdate = new Date();

  var isNight = (currentdate.getHours() > NIGHT_START_HOURS) || (currentdate.getHours() === NIGHT_START_HOURS && currentdate.getMinutes() >= NIGHT_START_MINUTES) ||
    (currentdate.getHours() < NIGHT_END_HOURS) ||(currentdate.getHours() === NIGHT_END_HOURS && currentdate.getMinutes() === NIGHT_END_MINUTES);
  var settingDarkmode = cookies.get('idexdarkmode');
  var darkMode = false;

  var cookieSetDuringNight = false;
  if (settingDarkmode != 0) {
    var settingDarkmodeDate = cookies.get('idexdarkmode-date');
    var dateCookie = new Date();
    dateCookie.setTime(settingDarkmodeDate);
    console.log("dateCookie : " + dateCookie);

    if ((dateCookie.getHours() > NIGHT_START_HOURS) || (dateCookie.getHours() === NIGHT_START_HOURS && dateCookie.getMinutes() >= NIGHT_START_MINUTES) ||
      (dateCookie.getHours() < NIGHT_END_HOURS) ||(dateCookie.getHours() === NIGHT_END_HOURS && dateCookie.getMinutes() === NIGHT_END_MINUTES)) {
      cookieSetDuringNight = true;
    }
  }
  console.log("currentdate : " + currentdate)
  console.log('darkmode:' + cookies.get('idexdarkmode'));
  console.log('darkmode-date:' + cookies.get('idexdarkmode-date'));
  console.log('isNight : ' + isNight);
  console.log('cookieSetDuringNight : ' + cookieSetDuringNight);

  if (isNight) {
    darkMode = true;
    if (settingDarkmode != 0)  {
      if (cookieSetDuringNight) {
        darkMode = (settingDarkmode == 1);
      }
    }
  }
  else {
    darkMode = false;
    if (settingDarkmode != 0)  {
      if (!cookieSetDuringNight) {
        darkMode = (settingDarkmode == 1);
      }
    }
  }

  if (darkMode) {
    var all = Drupal.Theme.load('all');
    $('body').addClass('dark-side');

    $('.item--dark-mode .label').each(function () {
      $(this).attr('data-text-init', $(this).text()).text(all.common.darkSideTextBack);
    });
  }

}(jQuery, Drupal, drupalSettings, window.Cookies));
